import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql, Link } from 'gatsby';
import ThemeChip from './ThemeChip';

export default function SelectedWorksResult(props) {
  const listQuery = useStaticQuery(graphql`
    query {
      allSanityTheme(
        sort: {order: ASC, fields: title}
        filter: { slug: { current: { ne: "null" } } }
      ) {
        nodes {
          id
          slug {
            current
          }
          title
        }
      }
    }
  `);
  const { data } = props;

  const themesList = listQuery.allSanityTheme.nodes.map((node) => ({
    id: node.id,
    slug: node.slug,
    title: node.title
  }));
  const projectHref = `/works/${data.slug}`;
  const themesSorted = data.themes.sort((a, b) => {
    if (a.title > b.title) return 1;
    if (a.title < b.title) return -1;
    return 0;
  });
  return (
    <Link className="selected-works-result" to={projectHref}>
      <GatsbyImage image={data.image} alt={data.title} />
      <div className="info-bar">
        <div className="theme-chips">
          {themesSorted.map((theme) => (
            <Link to={`/works/${theme.slug.current}`}>
              <ThemeChip
                key={theme.id}
                id={theme.id}
                themesList={themesList}
                place="selectedWorks"
              />
            </Link>
          ))}
        </div>
        <div className="title-chip">{data.title}</div>
      </div>
    </Link>
  );
}
