import React from 'react';

export default function ThemeChip(props) {
  const {
    id, themesList, place
  } = props;

  function getThemeChipSvg() {
    let chipSvg;
    const classNameString = place === 'result' ? 'theme-chip large' : 'theme-chip small';
    switch (id) {
      case themesList[0].id:
        chipSvg = (
          <svg className={classNameString} xmlns="http://www.w3.org/2000/svg" width="74" height="74" viewBox="0 0 74 74">
            <g>
              <rect width="74" height="74" rx="37" fill="#b0b3ff" />
              <text id="1" transform="translate(37 53)" fill="#222"><tspan x="-11.78" y="0">1</tspan></text>
            </g>
          </svg>
        );
        break;
      case themesList[1].id:
        chipSvg = (
          <svg className={classNameString} xmlns="http://www.w3.org/2000/svg" width="74" height="74" viewBox="0 0 74 74">
            <g>
              <rect width="74" height="74" rx="37" fill="#f54e20" />
              <text id="2" transform="translate(38 53)" fill="#fff"><tspan x="-11.78" y="0">2</tspan></text>
            </g>
          </svg>
        );
        break;
      case themesList[2].id:
        chipSvg = (
          <svg className={classNameString} xmlns="http://www.w3.org/2000/svg" width="74" height="74" viewBox="0 0 74 74">
            <g>
              <rect width="74" height="74" rx="37" fill="#ff2762" />
              <text id="3" transform="translate(37 53)" fill="#fff"><tspan x="-11.78" y="0">3</tspan></text>
            </g>
          </svg>
        );
        break;
      case themesList[3].id:
        chipSvg = (
          <svg className={classNameString} xmlns="http://www.w3.org/2000/svg" width="74" height="74" viewBox="0 0 74 74">
            <g>
              <rect width="74" height="74" rx="37" fill="#f2e500" />
              <text id="4" transform="translate(37 53)" fill="#222"><tspan x="-11.78" y="0">4</tspan></text>
            </g>
          </svg>
        );
        break;
      case themesList[4].id:
        chipSvg = (
          <svg className={classNameString} xmlns="http://www.w3.org/2000/svg" width="74" height="74" viewBox="0 0 74 74">
            <g>
              <rect width="74" height="74" rx="37" fill="#39ee69" />
              <text id="5" transform="translate(37 53)" fill="#222"><tspan x="-11.78" y="0">5</tspan></text>
            </g>
          </svg>
        );
        break;
      case themesList[5].id:
        chipSvg = (
          <svg className={classNameString} xmlns="http://www.w3.org/2000/svg" width="74" height="74" viewBox="0 0 74 74">
            <g>
              <rect width="74" height="74" rx="37" fill="#6c4fff" />
              <text id="6" transform="translate(37 53)" fill="#fff"><tspan x="-11.78" y="0">6</tspan></text>
            </g>
          </svg>
        );
        break;
      case themesList[6].id:
        chipSvg = (
          <svg className={classNameString} xmlns="http://www.w3.org/2000/svg" width="74" height="74" viewBox="0 0 74 74">
            <g>
              <rect width="74" height="74" rx="37" fill="#45bbff" />
              <text id="7" transform="translate(37 53)" fill="#fff"><tspan x="-11.78" y="0">7</tspan></text>
            </g>
          </svg>
        );
        break;
      default:
        // eslint-disable-next-line no-console
        console.error(id);
        throw new Error('unexpected theme value in getThemeChipSvg');
    }

    return chipSvg;
  }

  return (
    <>
      {getThemeChipSvg()}
    </>
  );
}
