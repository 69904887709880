import React, { useMemo } from 'react';
import SelectedWorksResult from './SelectedWorksResult';

export default function SelectedWorks(props) {
  const { projects } = props;
  const images = [];
  projects.forEach((project) => {
    images.push({
      id: `main_${project.id}`,
      title: project.title,
      themes: project.theme,
      slug: project.slug.current,
      image: project.mainImage.asset.gatsbyImageData
    });
    if (project.otherImages !== null) {
      project.otherImages.forEach((otherImage, i) => {
        images.push({
          id: `other_${i}_${project.id}`,
          title: project.title,
          themes: project.theme,
          slug: project.slug.current,
          image: otherImage?.asset?.gatsbyImageData
        });
      });
    }
  });
  function shuffle() {
    let m = images.length; let t; let
      i;
    while (m) {
      // eslint-disable-next-line no-plusplus
      i = Math.floor(Math.random() * m--);
      t = images[m];
      images[m] = images[i];
      images[i] = t;
    }
    return images;
  }
  const imagesShuffled = useMemo(() => shuffle(), []);

  return (
    <div className="results">
      {imagesShuffled.map((image) => (
        <SelectedWorksResult key={image.id} data={image} />
      ))}
    </div>
  );
}
